import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import HeadingImg from "../../assets/Taekwondo Images/Images/registration_heading.png"
import RegistrationBg from "../../assets/Taekwondo Images/Images/registration_bg.png"
import Files from 'react-files'
import {ReactComponent as SearchIcon} from "../../assets/icons/search.svg"
import {ReactComponent as ChampionshipLogo} from "../../assets/icons/no_registration.svg"
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosConfig from '../../axios_config/axios_instance'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import SuccessModal from './SuccessModal'
import { useNavigate } from 'react-router-dom'
import * as routesKey from "../../constants/routes"
import { setAthleteRegistrationFormData, setChamionshipRegisterFormData, setColorbeltPromotionFormData } from '../../redux/slices/registerClubSlice'
const OnlineRegestration = () => {
  const navigate = useNavigate()
  const [clubList, setClubList] = useState([])
const [headingTab, setheadingTab] = useState(1) 
const [heading, setHeading] = useState(false) 
const [colorBeltHeading, setcolorBeltHeading] = useState(false) 
  const [eventList, seteventList] = useState([])
     const {districtList} = useSelector(state=>state.home)
     const dispatch = useDispatch()
     
     const athleteRegistrationInitialValues={
        new_athlete_district:"",
        new_athlete_gender:"",
        new_athlete_name:"",
        new_athlete_dob:"",
        new_athlete_father:"",
        new_athlete_mother:"",
        new_athlete_guardian:"",
        new_athlete_at:"",
        new_athlete_post:"",
        new_athlete_pin:"",
        new_athlete_club:"",
        new_athlete_admission_date:"",
        new_athlete_aadhar:"",
        new_athlete_phone:"",
        new_athlete_photo:"",
        new_athlete_birth_certificate:"",

     }
     const athleteRegistrationSchema = Yup.object({
      new_athlete_name: Yup.string().trim().required("name required"), 
      new_athlete_mother: Yup.string().trim().required("mother name required"), 
      new_athlete_father: Yup.string().trim().required("father name required"), 
      // new_athlete_guardian: Yup.string().trim().required("name required"),
      new_athlete_dob: Yup.string().required("dob required"),
      new_athlete_at: Yup.string().trim().required("address required"),
      new_athlete_post: Yup.string().trim().required("address required"),
      new_athlete_pin: Yup.string().trim().required("Enter Your Pin Code").matches(/^[0-9]+$/, "Must be only digits").min(6, "Must be exactly 6 digits").max(6, "Must be exactly 6 digits"),
      // new_athlete_pin: Yup.string().trim().required("pin required"),
      new_athlete_club: Yup.string().trim().required("club required"),
      // new_athlete_aadhar: Yup.string().trim().required("aadhar required"),
      new_athlete_aadhar: Yup.string()
      .trim()
      .matches(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
      .required(),
      // new_athlete_phone: Yup.string().trim().required("phone required"),
      new_athlete_phone:Yup.string().trim().matches(/^[6-9]\d{9}$/,"Not a Valid Phone Number").required("please enter Your number").min(10).max(10),
      new_athlete_district: Yup.string().trim().required("district required"), 
      new_athlete_admission_date: Yup.string().required("dob required"),
      new_athlete_gender:Yup.string().required("Gender required"),
      new_athlete_birth_certificate:Yup.mixed().required("Photo required"),

     
   })


   const {
    athlete_values,
    handleAthleteBlur,
    handleAthleteChange,
    athlete_errors,
    athlete_touched,
    handleAthleteSubmit,
    resetAtheleteForm,
    setAthleteFieldValue,
  } = useFormik({
    initialValues:athleteRegistrationInitialValues,
    validationSchema: athleteRegistrationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
    }
  })



     const championshipInitialValues={
       name:"",
       ota_id:"",
       gender:"",
       dob:"",
       event:[],
       weight:"",
       district:"",
       club:"",
       athlete_photo:"",
       doc:""
     }
    const colorBeltInitialValues={
      athlete_ota_id:"",
      athlete_name:"",
      athlete_gender:"",
      athlete_dob:"",
      athlete_district:"",
      present_belt:"",
      applied_belt:"",
      register_club:"",
     }

     const colorBeltSchema = Yup.object({
        athlete_name: Yup.string().trim().required("name required"), 
        athlete_ota_id: Yup.string().trim().required("ID required"), 
        athlete_dob: Yup.string().required("dob required"),
        register_club:Yup.number().required("Club required"),
        athlete_district:Yup.number().required("District required"),
        athlete_gender:Yup.string().required("Gender required"),
     })
     const championshipSchema = Yup.object({
        name: Yup.string().trim().required("name required"), 
        ota_id: Yup.string().trim().required("ID required"), 
        weight: Yup.string().required("weight required"), 
        dob: Yup.string().required("dob required"),
        athlete_photo:Yup.mixed().required("Photo required"),
        club:Yup.number().required("Club required"),
        district:Yup.number().required("District required"),
        gender:Yup.string().required("Gender required"),
     })

     const {
      values,
      handleBlur,
      handleChange,
      errors,
      touched,
      handleSubmit,
      resetForm,
      setFieldValue,
    } = useFormik({
      initialValues:headingTab===1?championshipInitialValues:headingTab===2?colorBeltInitialValues:headingTab===3&&athleteRegistrationInitialValues,
      validationSchema: headingTab===1?championshipSchema:headingTab===2?colorBeltSchema:headingTab===3&&athleteRegistrationSchema,
      validateOnBlur: true,
      onSubmit: async (values) => {
        if(headingTab===1){

          if(values?.event?.length){
              try {
                const formdata = new FormData()
                formdata.append("aadhaar_card",values?.doc)
                formdata.append("athlete_photo",values?.athlete_photo)
                formdata.append("championship_heading_id",heading?.championship_heading_id)
                formdata.append("city_id",values?.district)
                formdata.append("dob",moment(values?.dob)?.format('DD-MM-YYYY'))
                formdata.append("event_id",values?.event)
                formdata.append("gender",values.gender)
                formdata.append("name",values?.name)
                formdata.append("ota_club_id",values?.club)
                formdata.append("weight",values?.weight)
                formdata.append("ota_id",values?.ota_id)
                
                const {data} = await axiosConfig.post(`/champion-registration-save`,formdata)
               if(data?.data?.status==="success"){
                resetForm()
                dispatch(setChamionshipRegisterFormData(data?.data))
                navigate(routesKey.CHAMPIONSHIPSTRIP)
               }else{
                toast.error(data?.data?.message)
               }
              } catch (error) {
                toast.error("Something went wrong")
              }
          }else{
            toast.error("Add at least one event")
          }
        }else if(headingTab===2){
          try {
            const formdata=new FormData()
            formdata.append("active_belt",values?.applied_belt)
            formdata.append("city_id",values?.athlete_district)
            formdata.append("color_belt_test_id",colorBeltHeading?.color_belt_test_id)
            formdata.append("dob", moment(values?.athlete_dob)?.format('DD-MM-YYYY'))
            formdata.append("name",values?.athlete_name)
            formdata.append("ota_club_id",values?.register_club)
            formdata.append("present_belt",values?.present_belt)
            formdata.append("gender",values?.athlete_gender)
            formdata.append("ota_id_number",values?.athlete_ota_id)
            
            const {data} = await axiosConfig.post(`/registration-test-save`,formdata)
            if(data?.data?.status==="success"){
              resetForm()
              navigate(routesKey.COLORBELTSTRIP)
              dispatch(setColorbeltPromotionFormData(data?.data))
              toast.success(data?.data?.message)
            }else{
              toast.error(data?.data?.message)
            }
          } catch (error) {
            toast.error("Something went wrong")
            
          }
        }else if(headingTab===3){
          const formdata = new FormData()
          formdata.append("athlete_name",values.new_athlete_name)
          formdata.append("father_name",values.new_athlete_father)
          formdata.append("mother_name",values.new_athlete_mother)
          formdata.append("guardian_name",values.new_athlete_guardian)
          formdata.append("aadhaar_no",values.new_athlete_aadhar)
          formdata.append("address_at",values.new_athlete_at)
          formdata.append("address_pin",values.new_athlete_pin)
          formdata.append("address_post",values.new_athlete_post)
          formdata.append("athlete_image",values.new_athlete_photo)
          formdata.append("club_admission_date",moment(values?.new_athlete_admission_date)?.format('DD-MM-YYYY'))
          formdata.append("district_name",values.new_athlete_district)
          formdata.append("dob", moment(values?.new_athlete_dob)?.format('DD-MM-YYYY'))
          formdata.append("gender",values.new_athlete_gender)
          formdata.append("mobile",values.new_athlete_phone)
          formdata.append("ota_club_name",values.new_athlete_club)
          formdata.append("proof_of_birth_certificate",values.new_athlete_birth_certificate)
          try {
            const {data} = await axiosConfig.post(`athlete-form-save`,formdata)
            if(data?.data?.status==="success"){
              resetForm()
              dispatch(setAthleteRegistrationFormData(data?.data?.athlete_data))             
              navigate(routesKey.ATHLETEREGESTRATIONSTRIP)
             }else{
              toast.error(data?.data?.message)
             }
          } catch (error) {
            toast.error("Something went wrong !!")
          }
        }
      }
    })

const handleError =(err)=>{
  toast.error(err?.message);

}

useEffect(()=>{
 const getEvents= async ()=>{
  try {
    const {data}= await axiosConfig.get(`/events?page=0&page_size=100`)
    seteventList(data?.data?.event_List)
  } catch (error) {
    
  }
 }
 getEvents()
},[])

// event selection
const handleAddEvent=(id)=>{
  if(id){
    if(values.event?.includes(id)){
      setFieldValue("event",values.event?.filter(v=>v!==id))
    }else{
      setFieldValue("event",[...values.event,id])
    }
  }
}


// heading apis call here

const getChampionshipHeading=async()=>{
  try {
    const {data} = await axiosConfig.get(`/championship-headings?status=1`)
     const heading= data?.data?.championship_heading_List?.find(h=>h.is_active===true)
     if(heading){
      setHeading(heading)
     }else{
      setHeading(false)
     }
  } catch (error) {
    setHeading(false)
  }
}

const getColorBeltPromotionHeading=async ()=>{
  try {
    const {data} = await axiosConfig.get(`/color-belt-tests`) 
    const heading= data?.data?.color_belt_test_data_list?.find(h=>h.is_active===true)
     if(heading){
      setcolorBeltHeading(heading)
     }else{
      setcolorBeltHeading(false)
     }
  } catch (error) {
    setcolorBeltHeading(false)
  }
}

useEffect(()=>{
  if(headingTab===1){
  resetForm()

    getChampionshipHeading()
  }else{
  resetForm()

    getColorBeltPromotionHeading()
  }
},[headingTab])

// city list api call here
useEffect(()=>{
  const getClubList=async()=>{
    try {
      const {data} = await axiosConfig.get(`/ota-club-list?city_id=${values?.district}&page=0&page_size=100`)
      setClubList(data?.ota_club_list)
    } catch (error) {
      
    }
  }
  if(values?.district){
    getClubList()
  }
},[values?.district])


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const getPlayerDetails= async()=>{
  try {
    const {data} = await axiosConfig.get(`/color-black-belts-details?ota_id=${values?.ota_id}`)
    if(data?.data?.color_belt_data){
       setFieldValue("gender",data?.data?.color_belt_data?.gender)
       setFieldValue("dob",moment(data?.data?.color_belt_data?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'))
       setFieldValue("district",data?.data?.color_belt_data?.city_id)
       setFieldValue("name",data?.data?.color_belt_data?.athlete_name)
    } 
  } catch (error) {
  }
}




// COLOR BELTPROMOTION REGESTRATION FORM FUNCTIONALITY
const present_belt_list=["NOVICE","WHITE","WHITE ONE","YELLOW","YELLOW ONE","GREEN","GREEN ONE","BLUE","BLUE ONE","RED","RED ONE","Poom/Dan1","Poom/Dan2","Poom/Dan3","Poom/Dan4","Dan-5","Dan-6","Dan-7","Dan-8","Dan-9"]
const applied_belt_list=["WHITE","WHITE ONE","YELLOW","YELLOW ONE","GREEN","GREEN ONE","BLUE","BLUE ONE","RED","RED ONE","Poom/Dan1","Poom/Dan2","Poom/Dan3","Poom/Dan4","Dan-5","Dan-6","Dan-7","Dan-8","Dan-9"]

const getColorBeltPlayerDetails= async()=>{
  try {
    const {data} = await axiosConfig.get(`/color-black-belts-details?ota_id=${values?.athlete_ota_id}`)
    if(data?.data?.black_belt_data ){
       setFieldValue("athlete_name",data?.data?.black_belt_data?.athlete_name)
       setFieldValue("athlete_dob",moment(data?.data?.black_belt_data?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'))
       setFieldValue("athlete_district",data?.data?.black_belt_data?.city_id)
       setFieldValue("athlete_gender",data?.data?.black_belt_data?.gender)
    }
  } catch (error) {
    
  }
}

useEffect(()=>{
  const getClubList=async()=>{
    try {
      const {data} = await axiosConfig.get(`/ota-club-list?city_id=${values?.athlete_district}&page=0&page_size=100`)
      setClubList(data?.ota_club_list)
    } catch (error) {
      
    }
  }
  if(values?.athlete_district){
    getClubList()
  }
},[values?.athlete_district])



const handleNavigate=()=>{
 navigate(routesKey.CHAMPIONSHIPSTRIP)
}
  return (
    <>

    <Template>
        <div className='flex h-[100%] flex-col gap-0'>
          

        <div className='h-[10%] max-md:h-[30%] relative  pt-2 w-full'>
         <img className='w-full max-md:h-[100px]' src={HeadingImg} alt=''/>
         <div className='absolute rounded-md flex flex-col gap-8 max-md:gap-0  h-max my-7  top-0 left-[50%] translate-x-[-50%] max-md:top-[-20%] max-md:left-[2%] max-md:translate-x-0 max-md:translate-y-0'>

            <p className='text-[42px] max-md:w-full max-md:text-[28px] font-bold font-["Quicksand"] md:text-center text-[#fff] text-center'>Online Registration</p>

            <div className='flex gap-3  max-md:w-full max-md:gap-1'>

            <button onClick={()=>setheadingTab(1)} className={`${headingTab===1?"bg-[#005AF5] text-[#D5D5D5]":"bg-[#FFF] text-[#002642]"} text-[19px] max-md:text-[12px] font-normal font-["Quicksand"] rounded-lg   text-center max-md:px-4 py-2 px-7`}>Tournament</button>
            <button onClick={()=>setheadingTab(2)} className={`${headingTab===2?"bg-[#005AF5] text-[#D5D5D5]":"bg-[#FFF] text-[#002642]"}text-[19px] font-normal max-md:text-[12px] whitespace-nowrap font-["Quicksand"] rounded-lg  max-md:px-1 text-center py-2 px-3`}>Belt Promotion Test</button>
            <button onClick={()=>setheadingTab(3)} className={`${headingTab===3?"bg-[#005AF5] text-[#D5D5D5]":"bg-[#FFF] text-[#002642]"}text-[19px] font-normal max-md:text-[12px] whitespace-nowrap font-["Quicksand"] rounded-lg  max-md:px-1 text-center py-2 px-3`}>Athlete Regestration</button>
                

            </div>
         </div>
        </div>

        {/* CHAMPIONSHIP REGESTRATION FROM */}
        {
          headingTab===1?
        <>
        {
          heading?
        <div className='w-full relative   min-h-[70%]'>
         <img src={RegistrationBg} alt='img' className='h-[150vh]  rounded-md w-full'/>

         <div className='absolute rounded-md bg-[rgba(245,245,245,0.82)] h-[150vh] overflow-auto w-[80%]    top-0 left-[50%] translate-x-[-50%] '>
             <p className='text-[50px] max-md:text-[24px] mx-5 py-2 text-[#002642] font-medium font-["Quicksand"] text-center'>{heading?.heading}</p>

        <div className='p-7 flex flex-col max-md:gap-4 gap-9'>

            {/* ota id and full name */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] after:content-["*"] after:ml-0.5 after:text-red-500 text-[27px] max-md:text-[14px] font-bold font-["Quicksand"]'>OTA ID No.</p>

                <div style={errors.ota_id&&touched.ota_id?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='ota_id' onChange={handleChange} value={values.ota_id} onBlur={handleBlur} placeholder='Fill here your OTA Id No.'   className='w-[90%]  bg-white max-md:placeholder:text-[20px] max-md:text-[20px] text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none '/>
                    {
                      values?.ota_id?.length>=1&&
                      

                        <SearchIcon className='w-[10%] cursor-pointer' onClick={getPlayerDetails}/>
                      
                    }
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full '>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Full Name</p>

                <div style={errors.name&&touched.name?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='name' disabled onChange={handleChange} value={values.name} onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px] bg-slate-100   text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                
            </div>

            {/* dob and gender */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Gender</p>

                <div style={errors.gender&&touched.gender?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select name='gender' onChange={handleChange} value={values.gender}  onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                    <option value={""}>Choose</option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                        <option value={"other"}>Other</option>
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Date of Birth </p>

                <div style={errors.dob&&touched.dob?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input type='date' disabled placeholder='Fill here ' name='dob' onBlur={handleBlur} onChange={handleChange} value={values.dob} max={moment().subtract(1,"day").format("YYYY-MM-DD")}   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-slate-100 text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            </div>

           {/* events */}
           <div>

           <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Event</p>
           <div className='flex max-md:justify-between md:gap-32'>
            <div>

             {
                eventList?.slice(0,eventList?.length/2)?.map((event,i)=>
           <div key={i} className='flex gap-5  items-center cursor-pointer' onClick={()=>handleAddEvent(event?.event_id)}>
             <input checked={values?.event.includes(event?.event_id)} type='checkbox' className='h-4 w-4'/>
             <p className='text-[28px] max-md:text-[14px] capitalize font-["Quicksand"] text-[#000000]'>{event?.event_name}</p>
           </div>
                )
             }
            </div>
            <div>

             {
                eventList?.slice(eventList?.length/2,eventList?.length)?.map((event,i)=>
           <div key={i} className='flex gap-5  items-center cursor-pointer' onClick={()=>handleAddEvent(event?.event_id)}>
             <input checked={values?.event.includes(event?.event_id)} type='checkbox' className='h-4 w-4'/>
             <p className='text-[28px] capitalize max-md:text-[14px] font-["Quicksand"] text-[#000000]'>{event?.event_name}</p>
           </div>
                )
             }
            </div>

           </div>
           </div>
            {/* weight and district name */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Weight</p>

                <div style={errors.weight&&touched.weight?{border:"1px red solid"}:{}}  className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input placeholder='Your weight '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none' value={values.weight} name='weight' onChange={handleChange} onBlur={handleBlur}/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>District Name</p>

                <div style={errors.district&&touched.district?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select name='district' onChange={handleChange} value={values.district} onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                      <option>Select</option>
                      {
                        districtList?.map((city,i)=>
                        <option key={i} value={city?.city_id}>{city?.city_name}</option>
                        )
                      }
                       
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
               
            </div>
                {/* ota register club name */}
                <div className='w-[100%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>OTA Registered Club Name</p>

                <div style={errors.club&&touched.club?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select disabled={!values?.district} name='club' onChange={handleChange} value={values.club} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                      <option>Select</option>
                    {
                        clubList?.map((club,i)=>
                        <option key={i} value={club?.ota_club_id}>{club?.club_name}</option>
                        )
                      }
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>

<div className='flex max-md:flex-col max-md:gap-4 w-full md:justify-between'>

               {/* Athlate photo */}
                <div className='w-[49%]  max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Athlete Photo</p>
                 <p className='text-[#44444]  text-[18px] max-md:text-[12px] font-medium font-["Quicksand"] '>(Only jpg,png and jpeg support upto 500kb)</p>
                <Files
                  className="files-dropzone cursor-pointer"
                   onChange={(file) =>
                     setFieldValue("athlete_photo",file[0])
                  }
                   onError={handleError}
                  accepts={["image/png", "image/jpeg", "image/jpg"]}
                  maxFileSize={500000}
                  minFileSize={0}
                  multiple
                  clickable
                >
                    <div style={errors.athlete_photo&&touched.athlete_photo?{border:"1px red solid"}:{}} className='h-12 flex rounded-md w-full border-[1px] border-[#002642] '>
                      <div className='w-[70%] max-md:text-[14px] rounded-md bg-white text-[#616161] text-center items-center flex  rounded-r-md text-[24px] px-4 font-["Quicksand"]'>
                      {values?.athlete_photo?`${values?.athlete_photo?.name?.split(".")[0].slice(0,20)}.${values?.athlete_photo?.name?.split(".")[1]}`:"Choose File to upload"}
                      </div>
                      <div className='w-[30%] max-md:text-[14px] text-[#ffdede] text-center items-center flex justify-center rounded-r-md text-[24px] font-["Quicksand"] bg-[#5A5A5A]'>
                      Browse File
                      </div>
                    </div>
                    
                </Files>
                </div>

                {/* Athlate adhaar card/ birth certifictae */}
                 
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642]  text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] '>Athlete Aadhar Card/Birth Certificate</p>
                <p className='text-[#44444] max-md:text-[12px] text-[18px]  font-medium font-["Quicksand"] '>(Only pdf,jpg,jpeg,png support upto 500kb)</p>
               
                <Files
                  className="files-dropzone cursor-pointer"
                  onChange={(file) =>
                    setFieldValue("doc",file[0])
                 }
                   onError={handleError}
                  accepts={[".pdf","image/jpeg", "image/jpg", "image/png"]}
                  maxFileSize={500000}
                  minFileSize={0}
                  multiple
                  clickable
                >
                    <div className='h-12 flex rounded-md w-full border-[1px] border-[#002642] '>
                      <div className='w-[70%] rounded-md bg-white text-[#616161] text-center items-center flex  rounded-r-md text-[24px] max-md:text-[14px] px-4 font-["Quicksand"]'>
                      {values?.doc?`${values?.doc?.name?.split(".")[0].slice(0,20)}.${values?.doc?.name?.split(".")[1]}`:"Choose File to upload"}
                      </div>
                      <div className='w-[30%] text-[#ffdede] text-center items-center flex justify-center rounded-r-md max-md:text-[14px] text-[24px] font-["Quicksand"] bg-[#5A5A5A]'>
                      Browse File
                      </div>
                    </div>
                    
                </Files>
                </div>
</div>
                <button type='submit'  className='flex justify-end max-md:justify-center '>

                <p onClick={handleSubmit} className='bg-[#002642] text-[24px] max-md:w-[60%] font-bold font-["Quicksand"] rounded-lg text-white w-[20%] text-center py-1 px-7'>Submit</p>
                </button>


                

            
        </div>
         </div>
        </div>:
        <div className='h-full w-full flex flex-col justify-center items-center gap-4 py-7 '>
          <ChampionshipLogo/>
          <p className='text-[50px] text-[#000] font-bold font-["Quicksand"]'>Championship is not going on</p>
          <p className='text-[40px] text-[#000] font-normal font-["Quicksand"]'>go to home page</p>
          </div>
        }
        </>:null}
{/* // COLOR BELT PROMOTION REGISTRATION FORM */}

{
          headingTab===2?
        <>
        {
          colorBeltHeading?
          <div className='w-full relative   min-h-[70%]'>
         <img src={RegistrationBg} alt='img' className='h-[100vh] rounded-md w-full'/>

         <div className='absolute rounded-md bg-[rgba(245,245,245,0.82)] h-screen overflow-auto w-[80%] max-md:[90%]   top-0 left-[50%] translate-x-[-50%]'>
             <p className='text-[50px] max-md:text-[24px] mx-5 py-2 text-[#002642] font-medium font-["Quicksand"] text-center'>{colorBeltHeading?.heading}</p>

        <div className='p-7 flex flex-col max-md:gap-4 gap-9'>

            {/* ota id and full name */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] after:content-["*"] after:ml-0.5 after:text-red-500 text-[27px] max-md:text-[14px] font-bold font-["Quicksand"]'>OTA ID No.</p>

                <div style={errors.athlete_ota_id&&touched.athlete_ota_id?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='athlete_ota_id'  onChange={handleChange} value={values.athlete_ota_id} onBlur={handleBlur} placeholder='Fill here your OTA Id No.'   className='w-[90%]  bg-white max-md:placeholder:text-[20px] max-md:text-[20px] text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none '/>
                    {
                      values?.athlete_ota_id?.length>=1&&
                      

                        <SearchIcon className='w-[10%] cursor-pointer' onClick={getColorBeltPlayerDetails}/>
                      
                    }
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Full Name</p>

                <div style={errors.athlete_name&&touched.athlete_name?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='athlete_name' disabled onChange={handleChange} value={values.athlete_name} onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                
            </div>

            {/* present belt and applied belt */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Present Belt</p>

                <div style={errors.present_belt&&touched.present_belt?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select name='present_belt' onChange={handleChange} value={values.present_belt}  onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                      <option>Select</option>
                    {
                      present_belt_list?.map((v,i)=>
                      <option key={i}>{v}</option>
                      )
                    }
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Applied Belt</p>

                <div style={errors.applied_belt&&touched.applied_belt?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select name='applied_belt' onChange={handleChange} value={values.applied_belt}  onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                    <option>Select</option>
                    {
                      applied_belt_list?.map((v,i)=>
                      <option key={i}>{v}</option>
                      )
                    }
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
           
            </div>

            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Gender</p>

                <div style={errors.athlete_gender&&touched.athlete_gender?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select name='athlete_gender' onChange={handleChange} value={values.athlete_gender}  onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                        <option value={""}>Choose</option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                        <option value={"other"}>Other</option>
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Date of Birth </p>

                <div style={errors.athlete_dob&&touched.athlete_dob?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input type='date' disabled name='athlete_dob' placeholder='Fill here '  onBlur={handleBlur} onChange={handleChange} max={moment().subtract(1,"day").format("YYYY-MM-DD")} value={values.athlete_dob}   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            </div>
         
            {/*  district name */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>District Name</p>

                <div style={errors.athlete_district&&touched.athlete_district?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select name='athlete_district' onChange={handleChange} value={values.athlete_district} onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                    <option>Select</option>

                      {
                        districtList?.map((city,i)=>
                        <option key={i} value={city?.city_id}>{city?.city_name}</option>
                        )
                      }
                       
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
               
            </div>
                {/* ota register club name */}
                <div className='w-[100%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>OTA Registered Club Name</p>

                <div style={errors.register_club&&touched.register_club?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select disabled={!values?.athlete_district} name='register_club' onChange={handleChange} value={values.register_club} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                      <option>Select</option>
                    {
                        clubList?.map((club,i)=>
                        <option key={i} value={club?.ota_club_id}>{club?.club_name}</option>
                        )
                      }
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>

               
                
                <button type='submit'  className='flex justify-end max-md:justify-center '>

                <p onClick={handleSubmit} className='bg-[#002642] text-[24px] max-md:w-[60%] font-bold font-["Quicksand"] rounded-lg text-white w-[20%] text-center py-1 px-7'>Submit</p>
                </button>


                

            
        </div>
         </div>
        </div>:
          <div className='h-full w-full flex flex-col justify-center items-center gap-4 py-7 '>
            <ChampionshipLogo/>
            <p className='text-[50px] text-[#000] font-bold font-["Quicksand"]'>Championship is not going on</p>
            <p className='text-[40px] text-[#000] font-normal font-["Quicksand"]'>go to home page</p>
            </div>
        }
        </>:null}


        {/* // Athlete registration */}

        {
          headingTab===3?
        
        
          
        <div className='w-full relative   min-h-[70%]'>
         <img src={RegistrationBg} alt='img' className='h-[150vh]  rounded-md w-full'/>

         <div className='absolute rounded-md bg-[rgba(245,245,245,0.82)] h-[150vh] overflow-auto w-[80%]    top-0 left-[50%] translate-x-[-50%] '>
             <p className='text-[50px] max-md:text-[24px] mx-5 py-2 text-[#002642] font-medium font-["Quicksand"] text-center'>OTA ATHLETE REGISTRATION FORM</p>

        <div className='p-7 flex flex-col max-md:gap-4 gap-9'>

            {/* father name and full name */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full '>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Full Name</p>

                <div style={errors.new_athlete_name&&touched.new_athlete_name?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='new_athlete_name'  onChange={handleChange} value={values?.new_athlete_name} onBlur={handleBlur} placeholder='Athlete name '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px] bg-slate-100   text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full '>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Father Name</p>

                <div style={errors.new_athlete_father&&touched.new_athlete_father?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='new_athlete_father'  onChange={handleChange} value={values.new_athlete_father} onBlur={handleBlur} placeholder='Father name '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px] bg-slate-100   text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                
            </div>
            {/* mother name and guardian */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full '>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Mother Name</p>

                <div style={errors.new_athlete_mother&&touched.new_athlete_mother?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='new_athlete_mother'  onChange={handleChange} value={values?.new_athlete_mother} onBlur={handleBlur} placeholder='Mother name '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px] bg-slate-100   text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full '>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] '>Guardian Name</p>

                <div style={errors.new_athlete_guardian&&touched.new_athlete_guardian?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input name='new_athlete_guardian'  onChange={handleChange} value={values.new_athlete_guardian} onBlur={handleBlur} placeholder='Guardian name '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px] bg-slate-100   text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                
            </div>

            {/* dob and gender */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Gender</p>

                <div style={errors.new_athlete_gender&&touched.new_athlete_gender?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-3 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <select name='new_athlete_gender' onChange={handleChange} value={values.new_athlete_gender}  onBlur={handleBlur} placeholder='Fill here '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'>
                    <option value={""}>Choose</option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                        <option value={"other"}>Other</option>
                    </select>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Date of Birth </p>

                <div style={errors.new_athlete_dob&&touched.new_athlete_dob?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input type='date'  placeholder='Fill here ' name='new_athlete_dob' onBlur={handleBlur} onChange={handleChange} value={values.new_athlete_dob} max={moment().format("YYYY-MM-DD")}   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-slate-100 text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
            </div>
           

         

            {/* club name and district name */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Club Name</p>

                <div style={errors.new_athlete_club&&touched.new_athlete_club?{border:"1px red solid"}:{}}  className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input placeholder='Club name '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none' value={values.new_athlete_club} name='new_athlete_club' onChange={handleChange} onBlur={handleBlur}/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>District Name</p>

                <div style={errors.new_athlete_district&&touched.new_athlete_district?{border:"1px red solid"}:{}}  className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input placeholder='District name '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none' value={values.new_athlete_district} name='new_athlete_district' onChange={handleChange} onBlur={handleBlur}/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>               
            </div>


                       {/* At and Post */}

            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>At</p>

                <div style={errors.new_athlete_at&&touched.new_athlete_at?{border:"1px red solid"}:{}}  className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input placeholder='Address '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none' value={values.new_athlete_at} name='new_athlete_at' onChange={handleChange} onBlur={handleBlur}/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Post</p>

                <div style={errors.new_athlete_post&&touched.new_athlete_post?{border:"1px red solid"}:{}}  className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input placeholder='Post office'   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none' value={values.new_athlete_post} name='new_athlete_post' onChange={handleChange} onBlur={handleBlur}/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>               
            </div>

            {/* pincode and addmission date */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Pincode</p>

                <div style={errors.new_athlete_pin&&touched.new_athlete_pin?{border:"1px red solid"}:{}}  className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input placeholder='Pincode ' type='number'   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none' value={values.new_athlete_pin} name='new_athlete_pin' onChange={handleChange} onBlur={handleBlur}/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Admission Date </p>

                <div style={errors.new_athlete_admission_date&&touched.new_athlete_admission_date?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input type='date'  placeholder='Fill here ' name='new_athlete_admission_date' onBlur={handleBlur} onChange={handleChange} value={values.new_athlete_admission_date} max={moment().format("YYYY-MM-DD")}   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-slate-100 text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>              
            </div>
            {/* Aadhaar card and phone nymber */}
            <div className='flex justify-between items-center max-md:flex-col max-md:gap-4'>
            <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Aadhar Number</p>

                <div style={errors.new_athlete_aadhar&&touched.new_athlete_aadhar?{border:"1px red solid"}:{}}  className='w-full justify-between flex  items-center p-2 bg-white border-[1px] border-[#002642] rounded-xl'>
                    
                    <input placeholder='Aadhar card number '   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-white text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none' value={values.new_athlete_aadhar} name='new_athlete_aadhar' onChange={handleChange} onBlur={handleBlur}/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Phone Number </p>

                <div style={errors.new_athlete_phone&&touched.new_athlete_phone?{border:"1px red solid"}:{}} className='w-full justify-between flex  items-center p-2 bg-slate-100 border-[1px] border-[#002642] rounded-xl'>
                    
                    <input  placeholder='Phone number ' name='new_athlete_phone' onBlur={handleBlur} onChange={handleChange} value={values.new_athlete_phone} max={moment().format("YYYY-MM-DD")}   className='w-[100%] max-md:placeholder:text-[20px] max-md:text-[20px]  bg-slate-100 text-[24px] placeholder:text-[#616161] placeholder:font-["Quicksand"] font-["Quicksand"] placeholder:text-[24px] outline-none'/>
                    {/* <SearchIcon className='w-[10%]'/> */}
               
                </div>
                </div>              
            </div>

               
                

<div className='flex max-md:flex-col max-md:gap-4 w-full md:justify-between'>

               {/* Athlate photo */}
                <div className='w-[49%]  max-md:w-full'>
                <p className='text-[#002642] text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] '>Athlete Photo</p>
                 <p className='text-[#44444]  text-[18px] max-md:text-[12px] font-medium font-["Quicksand"] '>(Only jpg,png and jpeg support upto 500kb)</p>
                <Files
                  className="files-dropzone cursor-pointer"
                   onChange={(file) =>
                     setFieldValue("new_athlete_photo",file[0])
                  }
                   onError={handleError}
                  accepts={["image/png", "image/jpeg", "image/jpg"]}
                  maxFileSize={500000}
                  minFileSize={0}
                  multiple
                  clickable
                >
                    <div style={errors.new_athlete_photo&&touched.new_athlete_photo?{border:"1px red solid"}:{}} className='h-12 flex rounded-md w-full border-[1px] border-[#002642] '>
                      <div className='w-[70%] max-md:text-[14px] rounded-md bg-white text-[#616161] text-center items-center flex  rounded-r-md text-[24px] px-4 font-["Quicksand"]'>
                      {values?.new_athlete_photo?`${values?.new_athlete_photo?.name?.split(".")[0].slice(0,20)}.${values?.new_athlete_photo?.name?.split(".")[1]}`:"Choose File to upload"}
                      </div>
                      <div className='w-[30%] max-md:text-[14px] text-[#ffdede] text-center items-center flex justify-center rounded-r-md text-[24px] font-["Quicksand"] bg-[#5A5A5A]'>
                      Browse File
                      </div>
                    </div>
                    
                </Files>
                </div>

                {/* Athlate birth certifictae */}
                 
                <div className='w-[49%] max-md:w-full'>
                <p className='text-[#002642]  text-[27px] max-md:text-[14px] font-bold font-["Quicksand"] after:content-["*"] after:ml-0.5 after:text-red-500'>Birth Certificate</p>
                <p className='text-[#44444] max-md:text-[12px] text-[18px]  font-medium font-["Quicksand"] '>(Only pdf,jpg,jpeg,png support upto 500kb)</p>
               
                <Files
                  className="files-dropzone cursor-pointer"
                  onChange={(file) =>
                    setFieldValue("new_athlete_birth_certificate",file[0])
                 }
                   onError={handleError}
                  accepts={[".pdf","image/jpeg", "image/jpg", "image/png"]}
                  maxFileSize={500000}
                  minFileSize={0}
                  multiple
                  clickable
                >
                    <div style={errors.new_athlete_birth_certificate&&touched.new_athlete_birth_certificate?{border:"1px red solid"}:{}} className='h-12 flex rounded-md w-full border-[1px] border-[#002642] '>
                      <div className='w-[70%] rounded-md bg-white text-[#616161] text-center items-center flex  rounded-r-md text-[24px] max-md:text-[14px] px-4 font-["Quicksand"]'>
                      {values?.new_athlete_birth_certificate?`${values?.new_athlete_birth_certificate?.name?.split(".")[0].slice(0,20)}.${values?.new_athlete_birth_certificate?.name?.split(".")[1]}`:"Choose File to upload"}
                      </div>
                      <div className='w-[30%] text-[#ffdede] text-center items-center flex justify-center rounded-r-md max-md:text-[14px] text-[24px] font-["Quicksand"] bg-[#5A5A5A]'>
                      Browse File
                      </div>
                    </div>
                    
                </Files>
                </div>
</div>
                <button type='submit'  className='flex justify-end max-md:justify-center '>

                <p onClick={handleSubmit} className='bg-[#002642] text-[24px] max-md:w-[60%] font-bold font-["Quicksand"] rounded-lg text-white w-[20%] text-center py-1 px-7'>Submit</p>
                </button>


                

            
        </div>
         </div>
        </div>:null}
        </div>
    </Template>
    </>
  )
}

export default OnlineRegestration


